import * as actionTypes from "../constants/checkoutConstants";
import * as actionTypes2 from "../constants/cartConstants";
import axios from "axios";

export const saveBasicDetails = (name, designation, company, phone) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.SAVE_BASIC_DETAILS,
    payload: {
      name: name,
      designation: designation,
      company: company,
      phone: phone
    },
  });

  localStorage.setItem("checkoutBasicDetails", JSON.stringify(getState().checkout.basicDetails));
};

export const saveFullDetails = (data) => async (dispatch, getState) => {
  dispatch({
    type: actionTypes.SAVE_BASIC_DETAILS,
    payload: data,
  });

  localStorage.setItem("checkoutFullDetails", JSON.stringify(getState().checkout.fullDetails));
};

export const razorpayVerification = (paymentId,orderId,signature) => async (dispatch, getState) => {
 
  try {
  dispatch({
    type: actionTypes.RAZORPAY_VERIFICATION_REQUEST
  });

  let fd = new FormData();
  fd.append('razorpay_payment_id', paymentId);
  fd.append('razorpay_order_id', orderId);
  fd.append('razorpay_signature', signature);

  const config = {
    header: {
      "Content-Type": "application/json",
    },
  };

  const {
    data
  } = await axios.post(`/api/orders/razorpay-verification`, fd, config);

  //console.log(data);

  if (data.status) {
    //localStorage.setItem("authToken", data.token);
    dispatch({
      type: actionTypes.RAZORPAY_VERIFICATION_SUCCESS,
      payload: data,
    });

  } else {
    dispatch({
      type: actionTypes.RAZORPAY_VERIFICATION_FAIL,
      payload: data.error
    });
  }


} catch (error) {
  dispatch({
    type: actionTypes.RAZORPAY_VERIFICATION_FAIL,
    payload: error.response && error.response.data.message ?
      error.response.data.message :
      error.message,
  });
}


};

export const placeOrder = (fullDetails, cartItems) => async (dispatch,getState) => {
  try {
    dispatch({
      type: actionTypes.PLACE_ORDER_REQUEST
    });


    let fd = new FormData();

    //user data exist

    const auth =  getState().auth;
    //const token = state.auth.auth.token;

    if (auth) {
      if(auth.auth){
        //if(auth.hasOwnProperty("user")){
          fd.append('user_id', auth.auth.user.id);
        //}
      }
    }

    //order details
    fd.append('name', fullDetails.name);
    fd.append('email', fullDetails.email);
    fd.append('create_account', fullDetails.createAccount);
    fd.append('password', fullDetails.password);
    fd.append('coupon', fullDetails.coupon);
    fd.append('phone', fullDetails.phone);
    fd.append('gst', fullDetails.gst);
    fd.append('add_line_1', fullDetails.addLine1);
    fd.append('add_line_2', fullDetails.addLine2);
    fd.append('city', fullDetails.addCity);
    fd.append('state', fullDetails.addState);
    fd.append('country', fullDetails.addCountry);
    fd.append('landmark', fullDetails.addLandmark);
    fd.append('zipcode', fullDetails.addZipcode);
    fd.append('add_type', fullDetails.addType);
    fd.append('is_gift', fullDetails.giftCard);

    //payment
    fd.append('payment_method', "razorpay");


    //const products = {}

    //product details

    //var Items = [];

    var items = cartItems.map((item) => {

      const data = {
        productId: item.product,
        variationId: item.variationId,
        quantity: item.qty,
        price:item.variation.price,
        sku:item.variation.sku,
        //cardDetails:item.cardDetails
        name:item.cardDetails.name,
        designation:item.cardDetails.designation,
        company:item.cardDetails.company,
        phone:item.cardDetails.phone,
        discount:0,
        discountTax:0,
        tax:0,
        total:Number(item.variation.price * item.qty),
        //logo:item.cardDetails.logo,
      }

      return data;
    })

    fd.append('items', JSON.stringify(items));

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    const {
      data
    } = await axios.post(`/api/orders`, fd, config);

    
    //console.log(data);

    if (data.status) {
      //localStorage.setItem("authToken", data.token);

        if(data.is_completed){
dispatch({
        type: actionTypes.PLACE_ORDER_COMPLETED,
        payload: true,
      });

        }else{
dispatch({
        type: actionTypes.PLACE_ORDER_SUCCESS,
        payload: data.result,
      });
        }

      

    } else {
      dispatch({
        type: actionTypes.PLACE_ORDER_FAIL,
        payload: data.message
      });
    }

  } catch (error) {
    dispatch({
      type: actionTypes.PLACE_ORDER_FAIL,
      payload: error.response && error.response.data.message ?
        error.response.data.message :
        error.message,
    });
  }
};

export const resetPlaceOrder = () => (dispatch) => {

  localStorage.removeItem("cart");
  localStorage.removeItem("checkoutFullDetails");
  localStorage.removeItem("checkoutBasicDetails");

  dispatch({ type: actionTypes.PLACE_ORDER_RESET });
  dispatch({ type: actionTypes.RESET_DETAILS });
  dispatch({ type: actionTypes2.CART_RESET });
};



