import "./faq.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link,withRouter} from "react-router-dom";


const FAQPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
  
    

  }, [dispatch]);

  return (

    <>

<section className="section">
    <div className="container">
    <div className="page-title-section">
                <div className="row">
                  <div className="col-lg-12">
                    <h3 className="title">Frequently Asked Questions</h3>
					
					</div>
                </div>
              </div>
    </div>
  </section>
  

  <div className="container ">

  <div className="faq mb-4">
	<div className="">
		<div className="row">
			<div className="col-lg-10 m-auto">
				<div className="block">
					
					<div className="faq-item">
					
						<div className="faq-item-title">
							<h2>
              General Information
							</h2>
						</div>
					
						<div id="gstAccordion" data-children=".item">
						  
						  <div className="item">
						  	<div className="item-link">
							    <a data-toggle="collapse" data-parent="#gstAccordion" href="#gstAccordion1">
                  I signed-up for TAP1CE Card but did not receive a confirmation email?
                    </a>
						  	</div>
						    <div id="gstAccordion1" className="collapse show accordion-block">
						      <p>
                  During high activity hours of the day, some confirmation emails take 2-4 hours to make it to your inbox. Please wait and see if you receive the confirmation email soon or get in touch with us through our support page and we will be happy to resolve this immediately.
                     </p>
						    </div>
						  </div>
						 
						  <div className="item">
						  	<div className="item-link">
							    <a data-toggle="collapse" data-parent="#gstAccordion" href="#gstAccordion2">
                  Is it necessary for me to own an NFC enabled smartphone to use it?
							    </a>
						  	</div>
						    <div id="gstAccordion2" className="collapse accordion-block">
						      <p>
                  No, it’s not necessary for you to own an NFC enabled smartphone to use the TAP1CE Card.
                   </p>
						    </div>
						  </div>
						
						  <div className="item">
						  	<div className="item-link">
							    <a data-toggle="collapse" data-parent="#gstAccordion" href="#gstAccordion3">

                  Should the other person have a phone that has NFC, to receive my information?
							    </a>
						  	</div>
						    <div id="gstAccordion3" className="collapse accordion-block">
						      <p>
						        
                  All Androids and all newer aged iPhones are compatible with  TAP1CE Card features. For older i-phones we recommending using the QR code feature. Please see here for our full compatibility list: <Link to="/supported-phones">https://tap1ce.uk/supported-phones</Link>

                    </p>
						    </div>
						  </div>
						 
						  <div className="item">
						  	<div className="item-link">
							    <a data-toggle="collapse" data-parent="#gstAccordion" href="#gstAccordion4">
                  Do you offer damage replacement or repair for TAP1CE cards?
							    </a>
						  	</div>
						    <div id="gstAccordion4" className="collapse accordion-block">
						      <p>
                  We understand how crucial it is for TAP1CE to work perfectly at every networking opportunity and we provide replacement for those cards that do not function as it should.
                   </p>
						    </div>
						  </div>
						 
						  <div className="item">
						  	<div className="item-link">
							    <a data-toggle="collapse" data-parent="#gstAccordion" href="#gstAccordion5">
                  I need personal assistance, how may I get in touch with you?
							    </a>
						  	</div>
						    <div id="gstAccordion5" className="collapse accordion-block">
						      <p>
                  We provide multiple avenues of communication to resolve your issues or answer your queries on our support page. Please choose how you would like to get in touch with us and we would be happy to provide you the personal assistance you require.
                   </p>
						    </div>
						  </div>
						 
						</div>
					</div>
					
					<div className="faq-item">
					
						<div className="faq-item-title">
							<h2>
              Product Info
							</h2>
						</div>
					
						<div id="accountAccordion" data-children=".item">
						  
						  <div className="item">
						  	<div className="item-link">
							    <a data-toggle="collapse" data-parent="#accountAccordion" href="#accountAccordion1">
                  Can I have multiple profiles on the same card?
							    </a>
						  	</div>
						    <div id="accountAccordion1" className="collapse accordion-block">
						      <p>
                  You may add all the information you need to share in multiple fields but you may only use one profile at a time.
                    </p>
						    </div>
						  </div>

						  <div className="item">
						  	<div className="item-link">
							    <a data-toggle="collapse" data-parent="#accountAccordion" href="#accountAccordion2">
                  Can TAP1CE Card’s be sanitised? Will it still work if exposed to water?
							    </a>
						  	</div>
						    <div id="accountAccordion2" className="collapse accordion-block">
						      <p>
                  Yes, TAP1CE Card can be sanitised without causing damage to the external or internal components of the card. It is also waterproof.
                    </p>
						    </div>
						  </div>
						
						  <div className="item">
						  	<div className="item-link">
							    <a data-toggle="collapse" data-parent="#accountAccordion" href="#accountAccordion3">
                  Can I switch between profiles at any time?
							    </a>
						  	</div>
						    <div id="accountAccordion3" className="collapse accordion-block">
						      <p>
                  Switching between profiles is very seamless and can be done at any time directly from your account.
                     </p>
						    </div>
						  </div>
						 
						  <div className="item">
						  	<div className="item-link">
							    <a data-toggle="collapse" data-parent="#accountAccordion" href="#accountAccordion4">
                  Is There A Monthly Subscription Fee?
							    </a>
						  	</div>
						    <div id="accountAccordion4" className="collapse accordion-block">
						      <p>
                  No, its Free For Lifetime.
                   </p>
						    </div>
						  </div>
						  
						  <div className="item">
						  	<div className="item-link">
							    <a data-toggle="collapse" data-parent="#accountAccordion" href="#accountAccordion5">
                  If my TAP1CE Card gets lost or stolen, will my information still be safe?
							    </a>
						  	</div>
						    <div id="accountAccordion5" className="collapse accordion-block">
						      <p>
                  If your card gets stolen, you can immediately go to Account and just deactivate the card.
                    </p>
						    </div>
						  </div>
						</div>
					</div>
				
					<div className="faq-item">
					
						<div className="faq-item-title">
							<h2>
							Order Information
							</h2>
						</div>
						
						<div id="plAccordion" data-children=".item">
						
						  <div className="item">
						  	<div className="item-link">
							    <a data-toggle="collapse" data-parent="#plAccordion" href="#plAccordion1">
                  How long does the card take to be delivered to me?
							    </a>
						  	</div>
						    <div id="plAccordion1" className="collapse accordion-block">
						      <p>
                  Due to the current COVID-19 pandemic, we are seeing varied delivery timings of upto 3-7 business days to delivery. We are working on ensuring faster delivery as the Lockdown eases.
                    </p>
						    </div>
						  </div>
						  
						  <div className="item">
						  	<div className="item-link">
							    <a data-toggle="collapse" data-parent="#plAccordion" href="#plAccordion2">
                  How can I track my order status?
							    </a>
						  	</div>
						    <div id="plAccordion2" className="collapse accordion-block">
						      <p>
						       
                  You may check the status of your order in the orders section of your account, or use the tracking ID shared by our delivery partner.
                   </p>
						    </div>
						  </div>
						  
						  <div className="item">
						  	<div className="item-link">
							    <a data-toggle="collapse" data-parent="#plAccordion" href="#plAccordion3">
                  How can I edit my order?
							    </a>
						  	</div>
						    <div id="plAccordion3" className="collapse accordion-block">
						      <p>
                  Please get in touch with us through any of the platforms provided for support. We will allow you to edit the order if it hasn't already been dispatched.
                   </p>
						    </div>
						  </div>
						  
						  <div className="item">
						  	<div className="item-link">
							    <a data-toggle="collapse" data-parent="#plAccordion" href="#plAccordion4">
                  I received a card with errors. What can I do about it?
							    </a>
						  	</div>
						    <div id="plAccordion4" className="collapse accordion-block">
						      <p>
						      Please reach out to us in this case. We will replace it with another card, provided the error was from our side.
                  </p>
						    </div>
						  </div>

						  <div className="item">
						  	<div className="item-link">
							    <a data-toggle="collapse" data-parent="#plAccordion" href="#plAccordion5">
                  How can I cancel my order?
							    </a>
						  	</div>
						    <div id="plAccordion5" className="collapse accordion-block">
						      <p>
						        
                  Please reach out to us on any platform, within 12hrs, to cancel the order.
                    </p>
						    </div>
						  </div>


              <div className="item">
						  	<div className="item-link">
							    <a data-toggle="collapse" data-parent="#plAccordion" href="#plAccordion6">
                  IS Tap Once Card SECURE?
							    </a>
						  	</div>
						    <div id="plAccordion6" className="collapse accordion-block">
						      <p>
						        
                  TAP1CE Card is as secure as any new-age technology and as safe as the information you put on it. It only works within an inch of a compatible device, and can only share, rather than extract information. In addition, there is no financial information stored.
                    </p>
						    </div>
						  </div>


						</div>
					</div>
				
				</div>
			</div>
		</div>
	</div>
</div>
</div>

    </>

  );
};

export default FAQPage;
