import "./payment-failed.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link,withRouter} from "react-router-dom";

import {Helmet} from "react-helmet";


const ThankYouPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
  
    

  }, [dispatch]);

  return (

    <section className="service section bg-gray">


      <Helmet>
      <script>{`{"gtag('event', 'conversion', {'send_to': 'AW-10795458521/SAQBCJncnf4CENm_1pso'});"}`}</script>
      </Helmet>

	<div className="container">

    <div className="row">
          <div className="col-lg-12">
            <div className="section-title">
            <h1 className="display-3">Oops!</h1>
              <p>Your payment could not be completed.</p>
            </div>
          </div>
    </div>

    <div className="row">
    <div className="col-lg-12 text-center">

      <p>
        Having trouble? <Link to="/contact-us">Contact us</Link>
      </p>
      <br/>

      <p className="lead">
        <Link className="btn btn-primary btn-sm" to={{pathname:`/my-account`,state:{menu:"orders"}}} role="button">My Orders</Link>
      </p>

      </div>
    </div>

    </div>
    </section>

   /*  <div className="jumbotron text-center">
      <h1 className="display-3">Thank You!</h1>
      <p className="lead"><strong>Please check your email</strong> for further instructions on how to complete your account setup.</p>
      <hr>
      <p>
        Having trouble? <a href="">Contact us</a>
      </p>
      <p className="lead">
        <a className="btn btn-primary btn-sm" href="https://bootstrapcreative.com/" role="button">Continue to homepage</a>
      </p>
    </div> */

  );
};

export default ThankYouPage;
