import {useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button,Form,Row,Col,Nav,Tab,Tabs,TabContainer,TabContent,TabPane,Spinner} from "react-bootstrap";
import {toast } from 'react-toastify';
import { Link } from "react-router-dom";

import { useAuth } from "../../providers/use-auth";
import { signUp } from "../../redux/actions/authActions";


import { SignUpContainer } from "./style";

//import loginbg from "../../images/login-bg.jpg";
//import loginBanner from "../../images/login-banner.png";

const SignUpPage = ({from,match, history}) => {

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [error, setError] = useState("");
  const [progress, setProgress] = useState(false);


  const dispatch = useDispatch();

  const fireAuth = useAuth();

  const signUpSelector = useSelector((state) => state.signUp);
  const { loading, error, auth } = signUpSelector;


  if(fireAuth.user){
    //history.push(`/my-account`);
  }


  useEffect(() => {

    if(error){
      toast.error(error);
     //return;
    }

    if(auth && fireAuth.user){
      //history.push(from ? from :`/my-account`);
      //history.goBack();
      history.push(`/my-account`);
    }

    if (loading) {
      // maybe trigger a loading screen
      //return;
    }

    //if (signUpuser) history.replace(from ? from: "/my-account");
  }, [auth,error, loading]);

  const signupHandler = async (e) => {
    e.preventDefault();
    setProgress(true);
    let response = await fireAuth.signup(username,email,password);

    if(response.hasOwnProperty("message")){

      //console.log("sign up error");
      //console.log(response.message);
      toast.error(response.message);
      setProgress(false);
      return;
  
    }
    
    if(response.hasOwnProperty("user")){
      //console.log(response.user);
      toast.success("Sign Up Done!");
      setProgress(false);
      dispatch(signUp(response.user));
      return;  
    }
    
  };
 
  return (
    <SignUpContainer style={{ background: "#17556D" }}>
    <section
      className="user-login section bg-white"
      style={{ backgroundImage: "url(https://dashboard.tap1ce.uk/media/login-bg.jpg)" }}
    >
      <div className="container">
        <div className="row justify-content-center align-items-center p-2">
          <div className="col-md-4">
            <div className="card p-2" style={{ borderRadius: "8px" }}>
              <img className="card-img-top" src="https://dashboard.tap1ce.uk/media/login-banner.png" alt="" />

              <div className="card-body">
                <div className="mb-4">
                  <h3 className="signup-title mb-1">Sign Up</h3>
                </div>

                <div className="content">


              <Form onSubmit={signupHandler}>
               
              <Form.Group controlId="formName">
              <label className="">
                                          <strong>Name</strong>
                                        </label>

                <Form.Control className="main" type="text" placeholder="Your Name"  value={username}
            onChange={(e) => setUsername(e.target.value)} required/>
              </Form.Group>

              <Form.Group controlId="formEmail">
              <label className="">
                                          <strong>Email Id</strong>
                                        </label>
                <Form.Control className="main" type="email" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} required />
              </Form.Group>

              <Form.Group controlId="formPassword">
              <label className="mb-2">
                                          <strong>Password</strong>
                                        </label>
                <Form.Control className="main" type="password" placeholder="Password" value={password}
            onChange={(e) => setPassword(e.target.value)} required />
              </Form.Group>

              <div className="text-center">
{loading || progress ? <Spinner animation="border" variant="primary" /> : 
              <Button variant="primary" type="submit" className="btn btn-primary btn-block">
                Sign Up
              </Button>
}
</div>

<div className='new-account mt-2 text-center'>
                                        <p className="p">
                                            Already have an account?{' '}
                                            <Link className='text-primary' to='/login'>
                                                Login
                                            </Link>
                                        </p>
                                    </div>


                 
              {/* <button className="btn btn-main-md">sign up</button> */}
                </Form>
             
           
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </SignUpContainer>
  );
};

export default SignUpPage;