import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";


import {
  Button,
  Form,
  Row,
  Col,
  Nav,
  Tab,
  Tabs,
  TabContainer,
  TabContent,
  TabPane,
  Spinner,
} from "react-bootstrap";
import { toast } from "react-toastify";

import { signIn, getUser } from "../../redux/actions/authActions";
import { useAuth } from "../../providers/use-auth";

import { LoginContainer } from "./style";

//import loginbg from "../../images/login-bg.jpg";
//import loginBanner from "../../images/login-banner.png";

const SignInPage = ({ match, history }) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [progress, setProgress] = useState(false);

  const signInSelector = useSelector((state) => state.auth);
  const { loading, error, auth } = signInSelector;

  const isAuthenticated = useSelector((state) => state.isAuthenticated);


  const fireAuth = useAuth();

  let returnUrl = (new URLSearchParams(window.location.search)).get("returnUrl");
  if(returnUrl){
    //console.log("returnUrl - "+returnUrl);
  }
 

  if (fireAuth.user && isAuthenticated) {

    if(returnUrl){
      //console.log("redirect to return url");
      history.push(`/`+returnUrl);
    }else{
      //console.log("redirect to my-account");
      history.push(`/my-account`);
    }
  }




  useEffect(async () => {


    if (error) {
      toast.error(error);
    }

    if (auth && fireAuth.user) {

      //console.log("user found");
      //history.push(from ? from :`/my-account`);
      //history.goBack();
      //toast.success("Sign In Done!");
      setProgress(false);
      dispatch(getUser);
      
      if(returnUrl){
        //console.log("redirect to return url");
        history.push(`/`+returnUrl);
      }else{
        //console.log("redirect to my-account");
        history.push(`/my-account`);
      }
      
      //history.push(`/my-account`);
    }else{
      //console.log("no user found");
    }

    if (loading) {
      // maybe trigger a loading screen
      //return;
    }

    //if (signInuser) history.replace(from ? from: "/my-account");
  }, [auth, loading, error]);

  const googleSignIn = async (e) => {
    e.preventDefault();
    setProgress(true);
    //console.log("googleSignIn");

    let response = await fireAuth.signInWithGoogle();

    if (response.hasOwnProperty("message")) {
      //console.log("sign in error");
      //console.log(response.message);

      toast.error(response.message);
      setProgress(false);

      return;
    }

    if (response.hasOwnProperty("user")) {
      //console.log(response.user);
      dispatch(signIn(response.user, "google"));
      return;
    }
  };

  const signinHandler = async (e) => {
    e.preventDefault();
    setProgress(true);
    //console.log("signinHandler");

    let response = await fireAuth.signin(email, password);

    if (response.hasOwnProperty("message")) {
      //console.log("sign in error");
      //console.log(response.message);

      toast.error(response.message);
      setProgress(false);

      return;
    }

    if (response.hasOwnProperty("user")) {
      //console.log(response.user);
      dispatch(signIn(response.user, "email"));
      return;
    }
  };

  return (
    <LoginContainer style={{ background: "#17556D" }}>
      <section
        className="user-login section bg-white"
        style={{ backgroundImage: "url(https://dashboard.tap1ce.uk/media/login-bg.jpg)" }}
      >
        <div className="container">
          <div className="row justify-content-center align-items-center p-2">
            <div className="col-md-4">
              <div className="card p-2" style={{ borderRadius: "8px" }}>
                <img className="card-img-top" src="https://dashboard.tap1ce.uk/media/login-banner.png" alt="" />

                <div className="card-body">
                  <div className="mb-4">
                    <h3 className="login-title mb-1">Login</h3>
                  </div>

                  <div className="content">
                    
                  
                    <Form onSubmit={signinHandler}>


				{/* 	<div className="form-group">
                                        <label className="mb-2 ">
                                          <strong>Mail Id</strong>
                                        </label>
                                        <input type="email" className="form-control"
											value={email}
                                           onChange={(e) => setEmail(e.target.value)}
										   placeholder="Type Your Email Address"
                                        />
                                      
									  </div> */}


                      <Form.Group controlId="formEmail">

					  <label className="mb-2 ">
                                          <strong>Mail Id</strong>
                                        </label>

                        <Form.Control
                          className="main"
                          type="email"
                          placeholder="Email Address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </Form.Group>

                      <Form.Group controlId="formPassword">

					  <label className="mb-2 d-flex flex-wrap align-items-center">
                                          <strong className="mr-auto">Password</strong>
                                          <Link to="/forgot-password">Forgot Password?</Link>
                                        </label>

                        <Form.Control
                          className="main"
                          type="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </Form.Group>

					  <div className="text-center">
                      {progress || loading ? (
                        <Spinner animation="border" variant="primary" />
                      ) : (
                        <Button
                          variant="primary"
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          Login
                        </Button>
                      )}
</div>

							<div className="new-account mt-2 text-center">
                                  <p className="p">
                                    Don't have an account?{" "}
                                    <Link className="text-primary" to="./sign-up">
                                      Create Account
                                    </Link>
                                    
                                  </p>
                                </div>

                    </Form>



                   {/*  <div className="m-4">
                      <p className="py-2">Or</p>

                      <Link
                        onClick={googleSignIn}
                        className="login-with-google-btn"
                      >
                        Sign in with Google
                      </Link>
                    </div>

                    <div className="new-acount">
                      <Link to="/forgot-password">Forgot your password?</Link>
                    </div> */}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </LoginContainer>
  );
};

export default SignInPage;
