import styled from "styled-components";

import appleImg from "../../images/apple.png";
import androidImg from "../../images/android.png";

export const SDContainer = styled.div`

.form-control:focus {
  box-shadow: none;
}

.form-control-underlined {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  padding-left: 0;
}

.form-control::placeholder {
  font-size: 0.95rem;
  color: #aaa;
  font-style: italic;
}

.box{
  background: linear-gradient(114.49deg, rgba(255, 255, 255, 0.33) -21.49%, rgba(255, 255, 255, 0) 111.75%);
  filter: drop-shadow(50px 60px 120px rgba(0, 0, 0, 0.03));
  backdrop-filter: blur(140px);
  border-radius: 24px;
}


.os-section{
  position:relative;
  margin-top: 100px;
}

.android{
  
}

.android:before {
  content: ' ';
  display: block;
  position: absolute;
  right: 50px;
  top: -100px;
  width: 150px;
  height: 150px;
  background-image:url(${androidImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ios{
  
}

.ios:before {
  content: ' ';
  display: block;
  position: absolute;
  right: 50px;
  top: -100px;
  width: 150px;
  height: 150px;
  background-image:url(${appleImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.os-title{
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 55px;
  text-align: left;
  text-transform: uppercase;
  color: #1A1A1A;
}

.brand-title{
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  text-align: left;
  line-height: 35px;
  text-transform: uppercase;
  color: #1A1A1A;
}

`;